.navbar {
    /* background-color: #D4FFA3; */

    background-color: #adceb3;
    /* background-color: #c9e0c0; */
    /* background-color: #9CAA91; */
    /* background-color: #A2BBA1; */
    /* background-color: #A8D297; */
    /* background-color: #d5f6c0; */

}

.Logo {
    width: 40%;
    margin-top: -1em;
    margin-bottom: -1em;
}

.navbar-expand-lg .navbar-nav {
    font-size: 1.4em;
    margin-right: -3em
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 2.5rem;
    color: black;


}

.navbar .navbar-nav .nav-link.active {
    color: black;
}

.navbar-expand-lg .navbar-nav .nav-link:hover {
    color: green;
}

.navbar-toggler-icon {
    color: black;
}

.navbar-light .navbar-toggler {
    border-color: black;
}

@media only screen and (max-width: 768px) {

    .Logo {
        width: 40%;
        margin-left: 30%;
        margin-top: -1em;
        margin-bottom: -1em;
    }
}