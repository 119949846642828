main {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #adceb3;

}

#aboutHeader,
#offerHeader,
#contactHeader {

    margin-top: 4em;
    margin-bottom: 1.5em;
    font-weight: bold;
}

.aboutSec,
.offerSec,
.contactSec {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: 768px) {

    #aboutHeader,
    #offerHeader,
    #contactHeader {

        margin-top: 2em;
        margin-bottom: 1.5em;
        font-weight: bold;
    }
}