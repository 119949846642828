.aboutSec .container {
    display: flex;
    justify-content: center;
}

.aboutSec {
    margin-top: 3em;
    margin-bottom: 7em;
    background-color: white;
    box-shadow: rgba(6, 70, 6, 0.2) 0px 7px 29px 0px;

}

    .portrait{
        width: 40%;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

    }


.aboutSec .container .col {
    margin: 1em;

}

.aboutSec .container .colText {
    display: block;
    text-align: center;
    justify-content: center;
    padding: 1em 2em 3em 2em;
}

/*.aboutSec .container .colText img {
    width: 50%;
    margin-top: 2em;
    border-radius: 30%;
    text-align: center;
    transition: transform .2s;
}*/

.imgPort-sm {

    margin-top: 2em;
    padding: 2em;
    text-align: center;
    transition: transform .2s;
}

.aboutSec .container .col .ForestImg {
    width: 95%;

}

.aboutSec .container p {
    text-align: justify;
    line-height: 1.7rem;
}

.aboutSec li {
    line-height: 1.7rem;
}


.aboutSec .list {
    /*list-style-type: none;*/
    display: block;
}


.aboutSec .list :hover {
    background-color: #adceb3
}

@media only screen and (max-width: 992px) {
    .aboutSec {
        width: 95vw;
    }
}

@media only screen and (max-width: 768px) {
    .aboutSec .list {
        width: 90%;

        /*list-style-type: none;*/

    }
}