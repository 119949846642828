.contactSec {
    width: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: white;

}


.container-fluid {
    width: 50%;
    background-color: #adceb3;
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 4rem;

}

.form-floating {
    padding: 1%;
}

.contactSec button {
    background-color: white;
    color: black;
    border: 3px rgb(170, 169, 169) solid;
    margin-left: 0.5em;

}


.btn-primary:hover {
    background-color: black;
    border: 3px white solid;

}

.colorTheme {
    width: 98vw;
    height: 20vh;
    background-color: #adceb3;
    /* background-attachment: fixed; */
}

.bg-img {
    width: 78vw;
    height: 60vh;
}

.gallery {
    display: flex;
    flex-direction: row;
}
.imgBox {
    margin: 2em;
    /*box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(173, 206,179) 0px 0px 0px 8px;*/
    box-shadow: rgb(173, 206,179) 0px 20px 30px -10px;
    /*box-shadow: rgba(173, 206,179, 0.25) 0px 54px 55px, rgba(173, 206,179, 0.12) 0px -12px 30px, rgba(173, 206,179, 0.12) 0px 4px 6px, rgba(173, 206,179, 0.17) 0px 12px 13px, rgba(173, 206,179, 0.09) 0px -3px 5px;*/
    /*box-shadow: rgba(173, 206,179, 0.12) 0px 2px 4px 0px, rgba(173, 206,179, 0.32) 0px 2px 16px 0px;*/
}

.emailIcon {
    color: #adceb3;
    font-size: 2.5em;
    margin-right: 5px;
}

@media only screen and (max-width: 768px) {

    .container-fluid {
        width: 80%;
        background-color: #adceb3;
        margin-top: 2rem;
        margin-bottom: 2rem;
        padding: 2rem;

    }
}