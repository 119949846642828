.headerSec .card-img-overlay {
    height: 50%;
}


.headerSec {
    background-image: url(../Sylke_outdoor21.jpeg);
    height: 100vh;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    /*filter: grayscale(100%);*/
    /*animation: colorChange 20s infinite;*/
}

@keyframes colorChange {
    10% {
        filter: grayscale(100%);
    }

    15% {
        filter: grayscale(90%);
    }

    20% {
        filter: grayscale(80%);
    }

    25% {
        filter: grayscale(70%);
    }

    30% {
        filter: grayscale(60%);
    }

    35% {
        filter: grayscale(50%);
    }

    40% {
        filter: grayscale(40%);
    }

    45% {
        filter: grayscale(30%);
    }

    50% {
        filter: grayscale(20%);
    }

    60% {
        filter: grayscale(10%);
    }

    65% {
        filter: grayscale(20%);
    }

    70% {
        filter: grayscale(30%);
    }

    80% {
        filter: grayscale(50%);
    }

    85% {
        filter: grayscale(60%);
    }

    90% {
        filter: grayscale(75%);
    }

    100% {
        filter: grayscale(90%);
    }

}


.headerSec .card-img-overlay .card-title {
    font-size: 3rem;
    font-weight: 900;
    margin-top: 3rem;
    color: #4d394a
}

.headerSec .card-text {
    font-size: 1.5rem;
}