@import url('https://fonts.googleapis.com/css?family=Raleway');

* {

  font-family: 'Raleway', sans-serif;
}

body {
  /* $main-color: #6e556b; */
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Raleway', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}



/* h2 {
  text-shadow: lightgray 0 -15px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
} */