.smallNote{
    font-size: 12px
}

#imgWaldbaden {

    width: 40%;
    left: 50%;
    opacity: 0.7;

}